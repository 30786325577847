.dashboard-overview {
  .dashboard-graph {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    .each-item {
      width: 27%;
      gap: 50px;
      padding: 20px;
      border-radius: 12px;
      background: #fff;
      box-shadow: -2px -2px 4px 0px rgba(0, 0, 0, 0.12),
        2px 2px 4px 0px rgba(0, 0, 0, 0.12);
      .title {
        color: #212121;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
      }
      .graph {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #212121;
        font-family: "Poppins";
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 42px */
      }
      .desc {
        gap: 8px;
        margin-top: 20px;
        .upClass {
          color: #35e328;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
        }
        .downClass {
          color: #e24242;
          font-family: "Poppins";

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
        }
      }
      .desc,
      .desc > div {
        display: flex;
        align-items: center;
        color: #808080;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
      }
    }
  }
}
