.drop-down-container-header {
  color: #111;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  /* 22.4px */
  .drop-down-label {
    font-family: inherit;
    color: #000;
    /* Button Small */
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 19.6px */
  }

  .select-value {
    margin-top: 8px;
    color: #111;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    border-radius: 4px;
    // border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
    outline: none;
    padding: 13px 16px;
    text-transform: capitalize;

    div {
      border: none;
      padding: 0px;
    }
  }

  .empty-value {
    font-weight: 400;
    color: #4f4f4f;
  }
}

.dev-report-header-container {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 50px;

  padding-top: 15px;
  padding-bottom: 10px;
  background-color: #f3f5f7;
  z-index: 11;
  margin-top: 44px;

  .personal {
    .name {
      color: #0b52d4;
      font-family: "Poppins";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      /* 28px */
    }

    .submit {
      color: #4f4f4f;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 20px;
    }

    .date {
      color: #212121;
      margin-top: 10px;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .data {
    text-align: right;

    // padding-right: 50px;
    .download {
      background: inherit;
      border-radius: 4px;
      border: 1px solid #062b6e;
      color: #062b6e;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      padding: 5px 10px;
      line-height: 20px;
      /* 142.857% */
      cursor: pointer;
    }

    .roundNUm {
      margin-top: 28px;
    }
  }
}
