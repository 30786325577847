.summary-container {
  .title,
  .techs {
    color: #212121;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 58px;
  }
  .techs {
    margin: 0px;
    display: flex;
    gap: 10px;
  }

  .challenge-table {
    margin-top: 30px;

    .table-header,
    .table-row {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .header-cell,
    .cell {
      padding: 20px 30px;
      border: 1px solid #ccc; // Add borders around each cell
      overflow: hidden;
    }

    .header-cell {
      color: #333;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .cell {
      color: #333;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      span {
        font-weight: 600;
      }
    }

    .question {
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .index-number {
      flex: 0 0 20px; // Fixed width for the index number column
      max-width: 50px;
    }

    .flex-cell {
      flex: 1; // Flex cells will take up equal space
    }

    .problem-name {
      flex: 1; // Take up the remaining space
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 24px;
      max-height: 45px; // Based on line-height for two lines
    }

    .status {
      &.partially-accepted {
        color: #ffa500; // Orange color for partial acceptance
      }
      &.accepted {
        color: #008000; // Green color for acceptance
      }
      &.rejected {
        color: #ff0000; // Red color for rejection
      }
    }

    .score,
    .time-spent {
      flex-basis: 100px; // Adjust as needed
    }

    .table-header {
      background-color: #eaeaea;
    }
  }
}

.detailed-report {
  .each-question {
    line-height: normal;
    margin-top: 50px;
    .titles {
      color: #212121;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 24px;
    }
    .desc {
      color: #4f4f4f;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      margin-top: 24px;
      line-height: 1.7em;
    }
    .score-main {
      display: flex;
      // max-width: 90%;
      justify-content: space-between;
      align-items: center;
      .score {
        border-radius: 4px;
        background: #0b52d4;
        padding: 9px 20px;
        color: #fff;
        text-align: center;
        font-family: "Poppins";
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 106.667% */
        letter-spacing: 0.6px;
        & > span {
          font-weight: 400;
        }
      }
    }
    .code-editor-main {
      height: fit-content;
      border-radius: 4px;
      border: 1px solid #e0e0e0;
      background: #fff;
      margin-top: 50px;
      padding: 20px;
      .desc {
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        margin-bottom: 20px;
      }
      .CodeMirror {
        min-height: 200px; /* Set your desired minimum height */
        border: 1px solid #ccc; /* Example border style */
        padding: 10px; /* Optional padding for aesthetics */
      }
    }
    .solution-data {
      .row-1 {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .title {
          margin-top: 0px;
        }
        .row-1-1 {
          display: flex;
          gap: 30px;
          .item-1 {
            border-radius: 4px;
            border: 1px solid #0bb762;
            color: #0bb762;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 7px 16px;
          }
          .item-2 {
            border-radius: 4px;
            background: #0b52d4;
            color: #fff;
            text-align: center;
            font-family: "Poppins";
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px; /* 106.667% */
            letter-spacing: 0.6px;
            padding: 7px 16px;
            .it2-1 {
              font-weight: 600;
            }
          }
        }
      }
      .row-2 {
        display: flex;
        gap: 30px;
        & > div {
          padding: 19px 24px;
          border-radius: 8px;
          background: #fff;
          color: #4f4f4f;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .item-1 {
          border: 1px solid #4318ff;
          & > span {
            color: #4318ff;
          }
        }
        .item-2 {
          border: 1px solid #0bb762;
          & > span {
            color: #0bb762;
          }
        }
        .item-3 {
          border: 1px solid #df7a03;
          & > span {
            color: #df7a03;
          }
        }
      }
    }
  }
}

.test-cases-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  th {
    color: #212121;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  td {
    color: #4f4f4f;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  th {
    background-color: #f4f4f4;
  }
}

.round-report-coding {
  .title {
    margin-top: 56px;
    color: #212121;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .data {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 30px;

    & > div {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 14px;
      border-radius: 8px;
      color: #4f4f4f;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .view-report {
    margin-top: 50px;

    & > button {
      border-radius: 6px;
      background: #062b6e;
      border: none;
      outline: none;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 12px 24px;
      cursor: pointer;
    }
  }
}

.assessment-report-coding {
  //   background-color: #fff;

  .top-group {
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
    // padding-right: 50px;
    // position: sticky;
    // top: 200px;
    // background: #fff;
    z-index: 15;

    .round-num {
      & > span:nth-child(1) {
        color: #4318ff;
        font-family: "Poppins";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      & > span:nth-child(2) {
        border-radius: 4px;
        background: rgba(225, 218, 254, 0.5);
        color: #212121;
        text-align: center;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        /* 100% */
        letter-spacing: 0.64px;
        padding: 8px 10px;
        margin-left: 8px;
      }
    }

    .status {
      display: flex;
      gap: 30px;

      & > div {
        text-align: center;
        font-family: "Poppins";
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 106.667% */
        letter-spacing: 0.6px;
        padding: 9px 10px;
        border-radius: 4px;
        color: #fff;

        & > span {
          font-weight: 600;
        }
      }

      & > div:nth-child(1) {
        background: #e15140;
      }

      & > div:nth-child(2) {
        background: #4318ff;
      }
    }
  }

  .question-data {
    margin-top: 12px;

    .each {
      border-bottom: 1px dashed #bdbdbd;
      margin-top: 40px;
      padding-bottom: 60px;

      .num {
        color: #212121;
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 25.2px */
        display: flex;
        justify-content: space-between;

        .answer {
          display: flex;
          gap: 27px;

          & > div:nth-child(1) {
            border-radius: 4px;
            border: 1px solid #0bb762;
            padding: 9px;
            color: #0bb762;
            text-align: center;
            font-family: "Poppins";
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            /* 106.667% */
            letter-spacing: 0.6px;
          }

          & > div:nth-child(2) {
            border-radius: 4px;
            border: 1px solid #0b52d4;
            color: #0b52d4;
            text-align: center;
            font-family: "Poppins";
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            /* 106.667% */
            letter-spacing: 0.6px;
            padding: 9px;
          }
        }
      }

      .desc {
        color: #212121;
        font-family: "Poppins";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 28px */
        margin-top: 24px;
      }

      .options {
        border-radius: 10px;
        overflow: hidden;
        margin-top: 36px;

        & > div {
          width: 90%;
          //   background: #f8f9fb;
          padding: 24px 30px;
          color: #212121;
          font-family: "Poppins";
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-bottom: 1px solid #e0e0e0;
          position: relative;
          z-index: 10;

          .correct {
            position: absolute;
            top: 0px;
            right: 0px;
            background-color: #0bb762;
            color: #fff;
            padding: 2px 4px;
            border-radius: 4px;
            font-weight: 400;
            font-size: 12px;
          }

          .candidate {
            position: absolute;
            top: 0px;
            left: 0px;
            background-color: #0bb762;
            color: #fff;
            padding: 2px 4px;
            border-radius: 4px;
            font-weight: 400;
            font-size: 12px;
          }
        }

        & > div:last-child {
          border: none;
        }
      }
    }
  }
}
