.container {
  padding: 60px 0px;
  background: #0b52d4;
  .subContainer {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    font-family: "Poppins";
    .leftSide {
      flex: 1;
      padding-right: 60px;
      position: relative;

      h2 {
        color: #fff;
        font-size: 32px;
        font-weight: 700;
      }

      p {
        color: #f7f7f7;
        font-size: 21px;
        font-weight: 500;
        line-height: 30px;
      }

      .numberRow {
        display: flex;
        justify-content: space-between;
        margin-top: 100px;
      }

      .numberCircle {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #ffffff;
        border: 6px solid #062b6e;
        font-size: 24px;
        font-weight: 700;
        color: #062b6e;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 0.3s ease, transform 0.3s ease;
      }

      .animateIn {
        opacity: 1;
        transform: translateY(0);
      }

      .iconWrapper {
        position: relative;
        height: 80px;
        margin-bottom: 20px;
      }

      .movingIcon {
        position: absolute;
        top: -50px;
        transition: transform 0.6s ease;
      }
    }

    .rightSide {
      flex: 1.5;
      position: relative;
      .step {
        position: relative;
        margin-bottom: 40px;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.3s ease, transform 0.3s ease;

        &.animateIn {
          opacity: 1;
          transform: translateY(0);
        }

        .stepHeader {
          display: flex;
          align-items: center;

          .stepNumber {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #fff;
            color: #062b6e;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            margin-right: 10px;
            border: 2px solid #062b6e;
          }

          h4 {
            color: #fff;
            font-size: 21px;
            font-weight: 700;
          }
        }

        .stepDesc {
          margin-left: 50px;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
        }

        .line {
          width: 2px;
          height: calc(100% + 40px);
          background-color: #fff;
          position: absolute;
          left: 20px;
          top: 50px;
          opacity: 0;
          transition: opacity 0.3s ease;

          &.visible {
            opacity: 1;
          }
        }
      }
    }
  }
}
