/* PageLayout.module.scss */
.layout {
  display: flex;
  flex-direction: column;
}

.content {
  margin-left: 200px; /* Adjust based on sidebar width */
  padding: 20px;
  flex-grow: 1;
}
