.container {
  background-size: cover;
  background-position: center;
  padding: 60px 0 90px 0;
  text-align: center;
  color: #fff;
}

.title {
  margin: 0px;
  padding: 0px;
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  letter-spacing: 0.2px;
}

.slider {
  max-width: 100%;
  margin: 0 auto;
  margin-top: 60px;

  .slick-list {
    padding: 0;
    overflow: hidden;
  }

  .slick-slide {
    padding: 0 10px; /* Padding on both sides for a total of 20px gap */
    box-sizing: border-box;
  }

  .slick-track {
    display: flex !important;
    align-items: stretch !important; /* Ensure all cards stretch to the same height */
  }

  .slick-arrow {
    display: none !important;
  }
}

.card {
  opacity: 0;
  transform: translateY(100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure content is spaced out */
  flex-grow: 1; /* Allow cards to grow based on content */
  width: 100%;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.24);
  padding: 20px;
  text-align: left;
  color: #333;
  box-sizing: border-box; /* Ensure padding is included in width calculation */
  transition: transform 0.6s ease, opacity 0.6s ease;
  min-height: 100%; /* Ensure all cards have the same height */
}

.animateIn {
  opacity: 1;
  transform: translateY(0);
}

.quoteText {
  margin: 0;
  padding: 0;
  color: #fff;
  font-family: "Cabin";
  font-size: 44px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 31px;
}

.cardTitle {
  margin: 0;
  padding: 0;
  color: #062b6e;
  text-align: center;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 31px;
  margin-top: 10px;
  text-align: left;
}

.cardDesc {
  color: #fff;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px;
  margin: 8px 0;
}

.authorSection {
  display: flex;
  align-items: center;
  margin-top: auto; /* Align the author section to the bottom */
}

.authorImage {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.authorName {
  color: #fff;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.authorTitle {
  color: #fff;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
