.clientPage {
  .main-layout {
    .animatedSection {
      opacity: 0;
      transform: translateY(100px);
      transition: opacity 0.6s ease, transform 0.6s ease;
    }

    .animateIn {
      opacity: 1;
      transform: translateY(0);
    }
    .footer-main {
      margin-top: 0px;
    }
  }
}
