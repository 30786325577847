.container {
  background-size: cover;
  background-position: center;
  //   padding: 50px;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 331px;
  opacity: 0;
  transform: translateY(100px); /* Initial state for the animation */
  transition: transform 0.6s ease, opacity 0.6s ease;

  .line1 {
    margin: 0;
    padding: 0;
    color: #fff;
    text-align: center;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px; /* 175% */
  }

  .line2 {
    margin: 0;
    padding: 0;
    color: #fff;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    margin-top: 20px;
  }
  .hireButton {
    color: var(--white, #fff);
    text-align: center;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.46px;
    padding: 15px 30px !important;
    color: white;
    cursor: pointer;
    background-color: #34a853 !important;
    box-shadow: none;
    margin-top: 50px;

    &:hover {
      background-color: #2f9048 !important;
      box-shadow: none;
    }
  }
}

.animateIn {
  opacity: 1;
  transform: translateY(0); /* Move the content into view */
}
