.container {
  background-size: cover;
  background-position: center;
  padding: 70px 0 50px 0;
  text-align: center;
  color: #fff;
  height: 100%;
}

.title {
  margin: 0;
  padding: 0;
  color: #212121;
  text-align: center;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
}

.techGrid {
  display: flex;
  justify-content: center;
  max-width: 75%;
  margin: 0 auto !important;
  margin-top: 40px !important;
}

.techField {
  padding: 17px 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-family: "Poppins";
  opacity: 0;
  transform: translateY(100px); /* Initial animation state */
  transition: transform 0.6s ease, opacity 0.6s ease;
  color: #fff;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
}

.techTitle {
  font-size: 1.5vw;
}

.animateIn {
  opacity: 1;
  transform: translateY(0); /* Move into view */
}

/* Add delays for staggered animation */
.techGrid .techField:nth-child(1),
.techGrid .techField:nth-child(2),
.techGrid .techField:nth-child(3),
.techGrid .techField:nth-child(4) {
  transition-delay: 0s; /* First row */
}

.techGrid .techField:nth-child(5),
.techGrid .techField:nth-child(6),
.techGrid .techField:nth-child(7),
.techGrid .techField:nth-child(8) {
  transition-delay: 0.3s; /* Second row with delay */
}
