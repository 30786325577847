.dashboard-header-row {
  .dashboard-header {
    .client-name {
      color: #175bd6;
      font-family: "Poppins";
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 30.8px */
    }
    .dashboard-desc {
      color: #999;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    }
  }
  .dashboard-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    .dropdown-and-dates {
      display: flex;
      align-items: center;
      gap: 15px;
      color: #4f4f4f;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }

    .time-range-dropdown {
      min-width: 150px;
    }
    .dashboard-tabs {
      display: flex;
      gap: 20px;
      border-radius: 40px;
      background: #fff;
      box-shadow: 2px 2px 4px 1px rgba(33, 33, 33, 0.12),
        2px 2px 4px 1px rgba(33, 33, 33, 0.12);
      width: fit-content;
      padding: 6px 10px;

      .tab {
        padding: 7px 18px;
        border-radius: 40px;
        background: #fff;
        cursor: pointer;
        color: #9e9e9e;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */

        &.active {
          background: #062b6e;
          color: #fff;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 22.4px */
        }
      }
    }
  }
}
