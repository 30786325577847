.createRoleContainer {
  //   padding: 20px;
  background-color: #f5f5f5;
  padding-top: 30px;
  .title {
    margin: 0;
    padding: 0;
    margin-top: 30px;
    color: #212121;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .roleFormContainer {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    .fieldWrapper {
      // margin-bottom: 20px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      max-width: 300px;
      .fieldLabel {
        margin-bottom: 8px;
        color: #212121;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
      }
    }

    .inputField,
    .dropdown {
      // padding: 10px;
      background-color: #f9f9f9;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 100%;
      margin-top: 8px;
      border-radius: 8px;
      border: 1px solid #e0e0e0;
      div {
        padding: 0px;
      }
      background: #f8f9fb;
      .fieldset {
        border: none;
      }
      input {
        padding: 14px 16px;
      }
    }
    .dropdown {
      // padding: 10px;
      background-color: #f9f9f9;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 100%;
      margin-top: 8px;
      border-radius: 8px;
      border: 1px solid #e0e0e0;
      div {
        padding: 14px 16px;
        border: none;
      }
      background: #f8f9fb;
      fieldset {
        border: none;
      }
      input {
        padding: 0px;
        height: 100%;
      }
    }

    .errorField {
      border-color: red;
    }

    .errorText {
      color: red;
      margin-top: 5px;
      font-size: 12px;
    }

    .row {
      display: flex;
      // justify-content: space-between;
      margin-bottom: 10px;
      gap: 50px;
    }

    /* Round Row */
    .roundRow {
      // margin-bottom: 20px;
      // gap: ;
      margin-top: 30px;
      .rowContent {
        display: flex;
        align-items: center;
        gap: 50px;
        .deleteIcon {
          color: #666666;
          margin-top: 27px;
        }
        .configButton {
          height: 40px;
          padding: 9px 20px;
          color: #062b6e;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 6px;
          border: 1px solid #062b6e;
          background: #fff;
          cursor: pointer !important;
          box-shadow: none;
          margin-top: 27px;
        }
      }
    }

    /* Set the width for individual fields */
    .inputField,
    .dropdown {
      min-width: 200px;
    }

    /* Drag and Drop Section */
    .uploadSection {
      margin-top: 50px;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      margin-top: 8px;
      gap: 40px;
      .dragDropArea {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 150px;
        border: 1px dashed #ccc;
        border-radius: 4px;
        // padding: 10px;
        cursor: pointer;
        transition: border-color 0.3s;
        border-radius: 8px;
        border: 1px dashed #e0e0e0;
        background: #fff;
        box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.03);
        height: 171px;
        width: 50%;
        color: #4f4f4f;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        span {
          color: #062b6e;
          font-weight: 500px;
        }

        .uploadIcon {
          font-size: 32px;
          margin-bottom: 10px;
        }

        .fileInput {
          display: none;
        }
      }
      .dragDropArea:hover {
        border-color: #062b6e;
      }
      .sampleFileButton {
        margin-left: 20px;
        border-radius: 8px;
        border: 1px solid #062b6e;
        background: inherit;
        color: #062b6e;
        font-size: 14px;
        padding: 13px 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    /* File display section */
    .fileDisplay {
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      background-color: #f9f9f9;
      padding: 5px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      color: #4f4f4f;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 4px;
      background: #fff;
      box-shadow: -2px -2px 4px 0px rgba(33, 33, 33, 0.05),
        2px 2px 4px 0px rgba(79, 79, 79, 0.1);
      background: inherit;
      border: none;
      & > button {
        color: #062b6e;
      }
      span {
        font-size: 14px;
        color: #333;
      }
    }

    .addRoundButton {
      padding: 0;
      color: #062b6e;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
      border: none;
      background: inherit;
      margin-top: 20px;
      & > span {
        margin-right: 8px;
      }
    }

    .iconButton {
      padding: 8px;
    }

    .bulk-upload-table {
      // padding: 20px;
      background-color: inherit;
      margin-top: 0px;
      .roundRow {
        margin-top: 15px;
      }
      .add-button {
        color: #062b6e;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        background: none;
        box-shadow: none;
        padding: 0px;
        margin-top: 17px;
      }
    }
  }
  .createButton {
    margin-top: 20px;
    border-radius: 8px;
    background: #062b6e;
    padding: 12px 24px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 40px;
  }
}
