.time-line-metrix {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: Arial, sans-serif;
  margin-top: 30px;
  border-radius: 12px;
  background: #fff;
  box-shadow: -2px -2px 4px 0px rgba(0, 0, 0, 0.12),
    2px 2px 4px 0px rgba(0, 0, 0, 0.12);
  .circular-loader {
    text-align: center;
    padding: 20px 0;
  }
  .week-navigator-container {
    display: flex;
    align-items: center;
    margin: 20px 0;
    padding: 0 16px;
    .timeline-container {
      color: #212121;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
    }
    .week-navigator-buttons {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      .arrow-button {
        color: #0b52d4;
        width: 22px;
        height: 22px;
        &:hover {
          background: #fff;
        }
      }
      .button-content {
        color: #333;
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 25.2px */
      }
    }
  }
  .header-row-container {
    // margin-bottom: 20px;
    padding: 0 16px;
    border-bottom: 1px solid #e0e0e0;
    .header-job-roles {
      .job-roles-text {
        padding-bottom: 10px;
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 18.2px */
      }
    }
    .header-candidates {
      .candidates-text {
        // padding-right: 10px;
        padding-bottom: 10px;
        color: #979797;
        font-family: "Poppins";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 18.2px */
        // border-right: 1px solid grey;
      }
    }
    .header-weekdays {
      .weekday-text {
        color: #4f4f4f;
        font-family: "Poppins";
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.6px */
        padding: 0 10px;
        padding-bottom: 10px;
      }
    }
  }
  .expandable-row-container {
    border-bottom: 1px solid #e0e0e0;
    margin-top: -10px;
    .main-row {
      align-items: center;
      // padding: 0 16px;
      padding: 0px;
      // padding-left: 16px;
      // border-bottom: 1px solid #e0e0e0;

      height: 52px;
      margin-top: 0px;

      .main-row-title {
        // border-left: 1px solid blue;
        .expand-button {
          text-transform: none;
          font-weight: bold;
          justify-content: flex-start;
          width: 100%;
          color: #696969;
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 16.8px */
          align-items: center;
          &:hover {
            background: #fff;
          }
          .expand-icon {
            margin-top: 8px;
          }
        }
      }
      .main-row-candidates {
        text-align: center;
        border-right: 1px solid #e0e0e0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .candidates-text {
          margin: auto 0;
          padding: 0;
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 16.8px */
        }
      }
      .main-row-progress-bar {
      }
    }
    .sub-rows-container {
      .sub-row {
        // margin-top: 8px;
        // padding: 0 16px;
        height: 52px;
        .sub-row-title {
          .sub-row-title-text {
            margin-left: 24px;
          }
        }
        .sub-row-candidates {
          border-right: 1px solid #e0e0e0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .sub-row-candidates-text {
            color: #999;
            font-family: "Poppins";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 16.8px */
          }
        }
        .sub-row-progress-bar {
        }
      }
    }
  }

  .progress-bar-container {
    display: grid; /* Ensure parent uses grid layout */
    grid-template-columns: repeat(7, 1fr); /* 7 equal grid columns */
    gap: 5px; /* Optional spacing between columns */
    width: 100%;
    height: 52px;
    background-color: #f0f4ff;
    position: relative;
    border-bottom: 1px solid #e0e0e0;
  
    .second-div {
      grid-column:  2/ 7; /* Hardcoded start from 2, end at 4 */
      height: 50%; /* 50% of parent */
      display: flex;
      position: absolute; /* Positioned at bottom */
      bottom: 0;
      width: 100%;
    }
  
    .inner-div {
      height: 100%;
    }
  
    .dark-blue {
      background-color: darkblue;
      flex: 4; /* Example flex percentage */
    }
  
    .light-blue {
      background-color: lightblue;
      flex: 5;
    }
  
    .grey {
      background-color: grey;
      flex: 1;
    }
  }
  
}
