.bannerContainer {
  position: relative;
  height: 90vh; /* 90% of the viewport height */
  width: 100%;
  background-color: #0b52d4;
  overflow: hidden; /* To prevent any content from overflowing */

  .textContent {
    position: absolute;
    top: 50%;
    left: 0;
    width: 60%;
    transform: translateY(-50%);
    color: #fff;
    z-index: 2; /* Higher z-index to ensure it stays on top of the video */
    padding-left: 6vh;

    .text1 {
      margin: 0;
      padding: 0;
      color: #fff;
      font-family: "Poppins";
      font-size: 2vw; /* Responsive font size */
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.2px;
      text-transform: capitalize;
    }

    .text2 {
      margin: 0;
      padding: 0;
      color: #fff;
      font-family: "Poppins";
      font-size: 1.5vw; /* Responsive font size */
      font-style: normal;
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: 0.2px;
      margin-top: 2vh;
      max-width: 600px;
    }

    .getStartedButton {
      border-radius: 4px;
      background: #34a853;
      color: #fff;
      text-align: center;
      font-family: "Poppins";
      font-size: 1.2vw; /* Responsive font size */
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.46px;
      padding: 1vh 2vw;
      border: none;
      outline: none;
      box-shadow: none;
      margin-top: 60px;

      &:hover {
        background: #339f50b5;
      }
    }
  }

  .videoContent {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;
    z-index: 1; /* Lower z-index so the text overlaps the video */
    overflow: hidden;
    border: 1px solid green;

    .bannerVideo {
      width: auto;
      height: 100%;
      object-fit: cover;
      opacity: 0.8;
    }
  }
}
