.codingConfigContainer {
  padding-top: 30px;
  .headerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .configTitle {
      margin: 0;
      padding: 0;
      color: #212121;
      font-size: 18px;
      font-weight: 600;
      line-height: normal;
    }

    .roundDetails {
      display: flex;
      gap: 20px;
      color: #212121;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      span {
        color: #7a7a7a;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }
  .detailsContains {
    background: var(--White, #fff);
    padding: 30px;
    margin-top: 30px;
    border-radius: 4px;
    .fieldWrapper {
      display: flex;
      flex-direction: column;
      max-width: 300px;
      width: 100%;
      margin-bottom: 16px;

      .fieldLabel {
        margin-bottom: 8px;
        color: #212121;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
      }
    }

    .inputField,
    .dropdown {
      background-color: #f9f9f9;
      border: 1px solid #ccc;
      border-radius: 8px;
      margin-top: 8px;
      width: 100%;

      input {
        padding: 14px 16px;
      }

      fieldset {
        border: none;
      }
    }

    .dropdown {
      div {
        padding: 14px 16px;
        border: none;
      }

      input {
        padding: 0px;
        height: 100%;
      }
    }
    .row {
      display: flex;
      gap: 30px;
    }
    .topicRow {
      display: flex;
      align-items: center;
      gap: 30px;
      margin-top: 40px;
    }
    .addTopicButton {
      color: #062b6e;
      font-size: 16px;
      border: none;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
      display: block;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0px;
    }

    .saveButton {
      border-radius: 8px;
      background: #062b6e;
      padding: 12px 24px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 40px;
    }

    .errorText {
      color: red;
      font-size: 12px;
      margin-top: 20px;
    }
  }
  .allowedLanguagesContainer {
    fieldset {
      border: none;
    }
    .allowedLanguagesTitle {
      color: #212121;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
    }

    .allowedLanguagesDropdown {
      border-radius: 8px;
      border: 1px solid #e0e0e0;
      background: #f8f9fb;
      margin-top: 16px;
      background-color: rgb(249 249 249);
      input {
        padding: 7px 9px !important;
        background: inherit;
      }
      .MuiOutlinedInput-root {
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        background: #f8f9fb;
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */

        &:hover .MuiOutlinedInput-notchedOutline {
          border-color: #e0e0e0;
        }

        &.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: #e0e0e0 !important; // Prevent highlight on focus
          box-shadow: none; // Remove any box shadow
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: #e0e0e0;
      }
    }
  }
}
