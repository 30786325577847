/* Sidebar.module.scss */
.sidebarWrapper {
  position: relative;
  box-sizing: unset;

  .sidebar {
    width: 240px;
    background-color: #f4f4f4;

    .logoContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      margin-top: 20px;

      .logo {
        width: 40px;
        height: auto;
      }
    }

    .menuList {
      .item {
        color: #b4b4b4;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px; /* 150% */
        .icons {
          width: fit-content;
          min-width: fit-content;
          margin-right: 20px;
          color: inherit;
          font-weight: inherit;
        }
        .label {
          color: inherit;
          font-weight: inherit;
        }
        span {
          font-weight: inherit;
          color: inherit;
        }
      }
      .selectedItem {
        color: #0b52d4;
        font-weight: 600 !important;
      }

      .MuiListItem-root {
        padding: 10px 20px;
      }

      .Mui-selected {
        background-color: #e0e0e0;
      }

      .MuiListItemIcon-root {
        min-width: 40px; /* Adjusts spacing for icons */
      }
    }
  }
}
