body {
  margin: 0px !important;
  padding: 0;
  background: #f8f9fb;
  background: #f3f5f7 !important;
  box-sizing: unset !important;
}
.clickedItem {
  z-index: 11 !important;
}
