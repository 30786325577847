.register-main {
  display: flex; /* Use flexbox layout */
  width: 100%; /* Full width of the viewport */
  height: 99vh; /* Full height of the viewport */
  margin: 0;
  padding: 0;
  .childDiv {
    // flex: 1; /* Each child takes up equal space */
    width: 50%; /* Half of the parent width */
    height: 100%; /* Full height of the parent */
    // border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left-image {
    // padding-right: 61px;
    // background: #e5dfff;
    background: #ffffff3d;
    box-shadow: inset -3px 3px 4px 0px rgba(150, 150, 150, 0.08);

    // width: fit-content;
    // min-width: calc(50% - 61px);
    width: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .item-content {
      // width: fit-content;
      // float: right;
      max-width: 95%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .main-logo {
      margin-right: 70%;
    }
    .logo-candidate {
      margin-top: 90px;
      width: 100%;
      max-width: 90%;

      & > img {
        width: 100%;
        height: auto;
        max-width: 80vh;
      }
    }
    .desc-main {
      text-align: center;
      color: #212121;
      font-size: 2rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 68px;
    }
  }
  .right-container-wrapper {
    // width: calc(50% - 100px);
    width: 50%;

    display: flex;
    justify-content: center;
    margin-top: 40px;
    align-items: start;
    .right-side-main {
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // padding: 20px;
      // padding-left: 80px;
      min-width: 450px;
      .divider {
        margin: 0;
        padding: 0;
        text-align: center;
        width: 90%;
        color: #a7a7a7;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        border-bottom: 1px solid #e0e0e0;
        height: 1px;
        position: relative;
        margin: 30px 0;
        max-width: 430px;
        font-family: "Poppins";

        span {
          // width: calc(100% - 20px);
          position: absolute;
          top: -10px;
          background: inherit;
          background: #f8f9fb;
          padding: 0 10px;
          margin-left: -20px;
        }
      }
      .sso-flow {
        width: 100%;
        max-width: 430px;
        margin-bottom: 24px;
        & > button {
          width: 100%;
          display: flex;
          padding: 13px 0;
          align-items: center;
          justify-content: center;
          gap: 20px;
          color: #4f4f4f;
          font-family: "Poppins";
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          border-radius: 8px;
          border: 1px solid #e0e0e0;
          outline: none;
          background: #fff;
          cursor: pointer;
          & > img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .login {
        width: 100%;
        max-width: 480px;
        text-align: center;
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        margin-top: 20px;
        & > span {
          color: #062b6e;
          font-weight: 500;
          cursor: pointer;
        }
      }
      .cookies {
        color: #979797;
        margin-top: 80px;
      }
      .form-main {
        .title {
          color: #212121;
          font-family: "Poppins";
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 36px;
          margin-top: 20px;
          margin-bottom: 30px;
        }
        label {
          display: block;
          color: #212121;
          font-family: "Poppins";
          margin-bottom: 8px;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
        }
        input {
          border-radius: 8px;
          border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
          outline: none;
          color: #4f4f4f;
          font-family: "Poppins";
          padding: 13px 16px;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          display: block;
          width: 100%;
          max-width: 430px;
        }
        input::placeholder {
          color: #a7a7a7;
        }
        .email {
          max-width: 395px;
          width: calc(100% - 32px);
          // margin-bottom: 30px;
        }
        .last-btn {
          border-radius: 8px;
          background: #062b6e;
          color: #fff;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 22.4px */
          outline: none;
          border: none;
          margin-top: 30px;
          cursor: pointer;
        }
        .otpError {
          margin-top: 6px;
          color: #e5261c;
          font-family: "Poppins";
          display: flex;
          align-items: center;
          gap: 6px;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 18.2px */
          margin-bottom: 30px;
        }
        .privacy-consent {
          display: flex;
          // border: 1px solid;
          gap: 8px;
          margin-top: 17px;
          justify-content: flex-start;
          align-items: center;
          & > input {
            width: fit-content;
            height: 24px;
            width: 24px;
            border: 1px solid #a7a7a7;
          }
          & > div {
            color: #4f4f4f;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
            & > span {
              color: #0b52d4;
              font-family: "Poppins";
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%;
              text-decoration-line: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .right-side-main-2 {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // padding-left: 60px;
    margin-top: 40px;
    .right-item {
      .title {
        color: #212121;
        font-family: "Poppins";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .subtitle {
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 10px;
        max-width: 481px;
      }
      .subtitle2 {
        width: 100%;
        //max-width: fit-content;
        & > span:nth-child(1) {
          font-weight: 600;
          line-height: 18px; /* 112.5% */
          margin: 0 4px;
        }
        & > span:nth-child(2) {
          color: #0b52d4;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
      .password-wrapper {
        width: 100%;
        max-width: 430px;
      }
      .label {
        color: #212121;
        font-family: "Poppins";
        font-size: 16px;
        margin-top: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        display: block;
      }
      .content-input {
        outline: none;
        border-radius: 8px;
        border: 1px solid rgba(17, 17, 19, 0.2);
        // max-width: 395px;
        width: 430px;
        padding: 13px 16px;
        // width: 100%;
        margin-top: 8px;
        background: #fff;
        color: #000;
        font-size: 16px;
        font-weight: 500;
      }
      .content-input::placeholder {
        color: #a7a7a7;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }

      /* WebKit (Chrome, Safari) */
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }

      /* Edge */
      input[type="number"] {
        -webkit-appearance: none;
      }

      .criteria {
        color: #34a853;
        font-family: "Poppins";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 6px;
        & > span {
          padding: 0 2px;
        }
        & > span:nth-child(1) {
          color: #434343;
          font-family: "Poppins";
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .otpError {
        margin-top: 6px;
        color: #e5261c;
        font-family: "Poppins";
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 18.2px */
      }

      .resendOtp {
        color: #888;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 112.5% */
        margin-top: 34px;
        & > span {
          color: #0b52d4;
          font-weight: 500;
          cursor: pointer;
        }
      }

      .last-button {
        max-width: 430px;
        width: 100%;
        padding: 13px 16px;
        outline: none;
        border: none;
        color: #fff;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        border-radius: 8px;
        background: #062b6e;
        margin-top: 44px;
        cursor: pointer;
      }
    }
  }
}
.Privacy-main-wrapper {
  & > div {
    // border: 2px solid red;
    width: 100%;
    height: 100%;
    & > div {
      width: 80%;
      height: 80%;
      margin: 5% auto;
      padding: 40px 0;
      border-radius: 12px;
      background: #fff;
      font-family: "Poppins" !important;
      h1,
      h2 {
        color: #212121;
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      h3,
      h4 {
        font-size: 14px;
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      p,
      li {
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      strong {
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
