.candidate-profiles-main {
  background: rgba(204, 222, 255, 0.3);
  font-family: "Poppins";
  padding: 60px 0 60px 0px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease, transform 0.6s ease;

  &.animate {
    opacity: 1;
    transform: translateY(0);
  }

  .header {
    color: #212121;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.2px;
  }

  .profile-card-container {
    margin-top: 40px;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease, transform 0.6s ease;

    &.animate-slider {
      opacity: 1;
      transform: translateY(0);
    }

    .slick-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.6s ease, transform 0.6s ease;
    }
  }
}
