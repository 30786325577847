.signupCandidateListWrapper {
  background-color: #f5f5f5;
  padding-top: 30px;

  .title {
    margin: 0;
    padding: 0;
    margin-top: 30px;
    color: #212121;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .userTableWrapper {
    .userTableContainer {
      // padding: 16px;
      background-color: #f8f9fa;
      margin-top: 30px;
    }

    .userTable {
      width: 100%;
      border-collapse: collapse;
    }

    .tableHeadRow {
      background-color: #ccdeff75;
    }

    .tableHeader {
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 153.846% */
    }

    .tableBodyRow {
      border: none;
      &:nth-child(even) {
        background-color: #f3f3f3;
      }
      td {
        border: none;
        color: var(--Secondary-Black, #616161);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
      }

      // &:hover {
      //   background-color: #ececec;
      // }
    }

    .moreIcon {
      color: #555;
    }

    .menu {
      .MuiMenuItem-root {
        font-size: 14px;
      }
    }
  }

  /* Pagination Styling */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: #062b6e;
    button {
      font-weight: 600;
    }
    /* Customizing pagination color */
    .MuiPaginationItem-root {
      color: inherit;
    }
  }
}

.deleteDialogSignUped {
  .dialogTitle {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    padding: 0px 42px;
    position: relative;
    color: #4f4f4f;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 143.75% */
    display: flex;
    flex-direction: column;
    .trashIcon {
      margin-top: 42px;
      color: #d32f2f; // Red color for the trash icon
      font-size: 24px;
    }
    & > span {
      margin-top: 20px;
      // padding: 0 42px;
    }
    .closeIcon {
      position: absolute;
      top: 0px;
      right: 0px;
      color: #999;
      cursor: pointer;

      &:hover {
        color: #666;
      }
    }
  }

  .dialogActions {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 30px;
    border: none;
    padding: 0px;
    margin-bottom: 42px;
  }
  .cancelButton,
  .deleteButton {
    padding: 11px 30px;
    color: #777;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .cancelButton {
    border-radius: 6px;
    border: 1px solid #777;
  }

  .deleteButton {
    border-radius: 6px;
    background: #dd524c;
    color: #fff;

    &:hover {
      background-color: #b71c1c;
    }
  }
}
