.hrInterviewConfigContainer {
  padding-top: 30px;

  .headerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .configTitle {
      margin: 0;
      padding: 0;
      color: #212121;
      font-size: 18px;
      font-weight: 600;
      line-height: normal;
    }

    .roundDetails {
      display: flex;
      gap: 20px;
      color: #212121;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;

      span {
        color: #7a7a7a;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }

  .detailsContains {
    background: var(--White, #fff);
    padding: 30px;
    margin-top: 30px;
    border-radius: 4px;

    .radioGroup {
      display: flex;
      // justify-content: space-between;
      // margin-top: 20px;
      gap: 50px;
      flex-direction: row;

      .radioLabel {
        font-size: 16px;
        color: #212121;
        font-weight: 500;
      }

      .radioOption {
        display: flex;
        align-items: center;

        input[type="radio"] {
          margin-right: 10px;
        }
      }
    }

    .formRow {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-top: 30px;

      .textField {
        flex: 1;
      }
    }

    .fieldWrapper {
      display: flex;
      flex-direction: column;
      max-width: 300px;
      width: 100%;
      margin-bottom: 16px;

      .fieldLabel {
        margin-bottom: 8px;
        color: #212121;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
      }
    }

    .inputField,
    .dropdown {
      background-color: #f9f9f9;
      border: 1px solid #ccc;
      border-radius: 8px;
      margin-top: 8px;
      width: 100%;

      input {
        padding: 14px 16px;
      }

      fieldset {
        border: none;
      }
    }

    .dropdown {
      div {
        padding: 14px 16px;
        border: none;
      }

      input {
        padding: 0px;
        height: 100%;
      }
    }

    .note {
      color: #7a7a7a;
      font-size: 12px;
      margin-top: 10px;
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      text-align: left;
    }

    .saveButton {
      border-radius: 8px;
      background: #062b6e;
      padding: 12px 24px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 40px;
    }

    .errorText {
      color: red;
      font-size: 12px;
      margin-top: 20px;
    }
  }
}
