.container {
  background-size: cover;
  background-position: center;
  padding: 60px 0;
  text-align: center;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.title {
  margin: 0px;
  padding: 0px;
  color: #212121;
  text-align: center;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
}

.offersGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Ensure 3 items per row */
  gap: 40px;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 30px;
}

.offerBox {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 16px 20px;
  border-radius: 8px;
  transition: transform 0.6s ease, opacity 0.6s ease;
  opacity: 0;
  transform: translateY(100px); /* Initial hidden state */
  text-align: center;
  box-shadow: -2px -2px 4px rgba(33, 33, 33, 0.08),
    2px 2px 4px rgba(33, 33, 33, 0.08);
}

.icon {
  margin-bottom: 10px;
}

.icon img {
  width: 60px;
  height: 60px;
}

.offerTitle {
  color: #062b6e;
  text-align: center;
  font-family: "Poppins";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  margin: 10px 0;
}

.offerDesc {
  color: #4f4f4f;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.animateIn {
  opacity: 1;
  transform: translateY(0); /* Moves the row into view */
}
