.dev-report-mcq {
  max-width: 95%;

  .details {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    padding-top: 10px;
    background-color: #fff;
    z-index: 11;

    .personal {
      .name {
        color: #4318ff;
        font-family: "Poppins";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 28px */
      }

      .submit {
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 20px;
      }

      .date {
        color: #212121;
        margin-top: 10px;
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .data {
      text-align: right;

      // padding-right: 50px;
      .download {
        background: inherit;
        border-radius: 4px;
        border: 1px solid #4318ff;
        color: #4318ff;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        padding: 5px 10px;
        line-height: 20px;
        /* 142.857% */
        cursor: pointer;
      }

      .roundNUm {
        margin-top: 28px;
      }
    }
  }

  .title {
    margin-top: 56px;
    color: #212121;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .round-report {
    .title {
      margin-top: 56px;
      color: #212121;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .data {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin-top: 30px;

      & > div {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 14px;
        border-radius: 8px;
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .view-report {
      margin-top: 50px;

      & > button {
        border-radius: 6px;
        background: #062b6e;
        border: none;
        outline: none;
        color: #fff;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 12px 24px;
        cursor: pointer;
      }
    }
  }

  .assessment-report {
    // background-color: #fff;

    .top-group {
      padding-top: 64px;
      display: flex;
      justify-content: space-between;
      // padding-right: 50px;
      // position: sticky;
      // top: 127px;
      // background: #fff;
      z-index: 11;

      .round-num {
        & > span:nth-child(1) {
          color: #4318ff;
          font-family: "Poppins";
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        & > span:nth-child(2) {
          border-radius: 4px;
          background: #ccdeff8f;
          color: #212121;
          text-align: center;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          /* 100% */
          letter-spacing: 0.64px;
          padding: 8px 10px;
          margin-left: 8px;
        }
      }

      .status {
        display: flex;
        gap: 30px;

        & > div {
          text-align: center;
          font-family: "Poppins";
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          /* 106.667% */
          letter-spacing: 0.6px;
          padding: 9px 10px;
          border-radius: 4px;
          color: #fff;

          & > span {
            font-weight: 600;
          }
        }

        & > div:nth-child(1) {
          background: #e15140;
        }

        & > div:nth-child(2) {
          background: #4318ff;
        }
      }
    }

    .question-data {
      margin-top: 12px;

      .each {
        border-bottom: 1px dashed #bdbdbd;
        margin-top: 40px;
        padding-bottom: 60px;

        .num {
          color: #212121;
          font-family: "Poppins";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
          /* 25.2px */
          display: flex;
          justify-content: space-between;

          .answer {
            display: flex;
            gap: 27px;

            & > div:nth-child(1) {
              border-radius: 4px;
              border: 1px solid #0bb762;
              padding: 9px;
              color: #0bb762;
              text-align: center;
              font-family: "Poppins";
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              /* 106.667% */
              letter-spacing: 0.6px;
            }

            & > div:nth-child(2) {
              border-radius: 4px;
              border: 1px solid #0b52d4;
              color: #0b52d4;
              text-align: center;
              font-family: "Poppins";
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              /* 106.667% */
              letter-spacing: 0.6px;
              padding: 9px;
            }
          }
        }

        .desc {
          color: #212121;
          font-family: "Poppins";
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          /* 28px */
          margin-top: 24px;
        }

        .options {
          border-radius: 10px;
          overflow: hidden;
          margin-top: 36px;
          .each-question {
            display: flex;
            .response {
              margin-left: 24px;
              white-space: pre;
              .correct {
                // position: absolute;
                color: #0bb762;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                display: flex;
                align-items: center;
              }

              .candidate {
                color: #e15140;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                display: flex;
                align-items: center;
                margin-top: 12px;
              }
            }
          }

          .each-option {
            width: 90%;
            background: #ffffff;
            max-width: 500px;
            padding: 24px 30px;
            color: #212121;
            font-family: "Poppins";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-bottom: 1px solid #e0e0e0;
            position: relative;
            z-index: 10;
            // white-space: pre;
          }

          & > div:last-child {
            border: none;
          }
        }
      }
    }
  }
}
