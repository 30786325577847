.container {
  background: #e0ebff;
  padding: 60px 0px;
  font-family: "Poppins", sans-serif;

  .subContainer {
    max-width: 80%;
    margin: 0 auto;

    .part1Title,
    .part2Item,
    .part3Grid,
    .part4Container {
      opacity: 0;
      transform: translateY(100px);
      transition: transform 0.6s ease, opacity 0.6s ease;
    }

    .part1Title {
      margin: 0;
      padding: 0;
      color: #212121;
      text-align: center;
      font-family: "Poppins", sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: 0.2px;
    }

    .gridContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* 2 items per row */
      gap: 40px;
      margin-top: 50px;
      .part2Item {
        display: grid;
        grid-template-columns: 40px 1fr; /* Image in col 1, title in col 2 */
        grid-template-rows: auto auto; /* Title in row 1, desc in row 2 */
        gap: 20px;
        .part2Image {
          grid-row: 1 / 3; /* Span both rows */
          width: 40px;
          height: 40px;
          flex-shrink: 0;
        }
        .part2Title {
          margin: 0;
          padding: 0;
          margin-top: 10px;
          color: #062b6e;
          font-weight: 600;
          font-size: 18px; /* Adjust as per design */
          align-self: center; /* Center the title vertically relative to the image */
        }

        .part2Desc {
          margin: 0;
          padding: 0;
          margin-top: 10px;
          grid-column: 2 / 3; /* Start the description from the second column */
          color: #4f4f4f;
          font-weight: 400;
        }
      }
    }

    .part3Row {
      display: flex;
      justify-content: space-between;
      gap: 100px;
      margin-top: 50px;
      .part3Item {
        flex: 1;
        .part3ImageContainer {
          text-align: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;

          .part3ImageTitle {
            color: #062b6e;
            text-align: center;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.2px;
          }
          .part3Image {
            width: 100%;
            height: auto;
            border-radius: 8px;
          }

          .part3ImageContainer:hover .part3Image {
            transform: scale(1.05);
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
    .part4Title {
      color: #212121;
      text-align: center;
      font-family: "Poppins";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.2px;
      margin-top: 60px;
    }
    .buttonContainer {
      margin-top: 30px;
      text-align: center;
      .part4Button {
        background-color: #062b6e;
        color: #fff;
        padding: 12px 30px;
        border: none;
        font-weight: 600;
        cursor: pointer;
        text-transform: none;
        transition: background-color 0.3s;
        border-radius: 4px;
        background: #34a853;

        &:hover {
          background: #096d24;
        }
      }
    }

    .animateIn {
      opacity: 1;
      transform: translateY(0);
      transition: transform 0.6s ease, opacity 0.6s ease;
    }
  }
}
