/* Navbar.module.scss */
.navbar {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  padding: 14px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Ensure padding is inside the box */
  padding-right: 40px;
  button {
    padding: 0px;
  }

  .rightItems {
    display: flex;
    align-items: center;

    .customerSupport {
      display: flex;
      align-items: center;
      margin-right: 40px;
      gap: 8px;
      color: #4f4f4f;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;

      & > span,
      & > button {
        font-weight: inherit;
        font-size: inherit;
        color: inherit;
      }
    }

    .userIcon {
      position: relative;
      color: #8f8f99;

      .MuiIconButton-root {
        padding: 8px; /* Override default padding for IconButton */
        font-size: 16px; /* Override font size */
        color: inherit; /* Customize icon color */
      }

      /* Override dropdown menu */
      .MuiMenu-paper {
        padding: 10px;
        background-color: #f9f9f9; /* Custom background */
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      }

      /* Override MenuItem */
      .MuiMenuItem-root {
        font-size: 14px; /* Custom font size */
        padding: 8px 16px; /* Custom padding */
        &:hover {
          background-color: #e0e0e0; /* Custom hover background */
        }
      }
    }
  }
}
