.roundStartOrComplete {
  margin-left: 5vw;
  font-family: "Poppins";
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  background: #f5f5f5;
  padding: 20px 30px;
  max-width: 800px;
  margin-top: 50px;
  & > div:nth-child(1) {
    border-radius: 3px;
    background: #ccdeff;
    padding: 7px 16px;
    width: fit-content;
  }
  & > div:nth-child(2) {
    color: #4f4f4f;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 16px;
    text-transform: capitalize;
  }
  & > div:nth-child(3) {
    color: #4f4f4f;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  & > div:nth-child(4) {
    color: #222;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%; /* 21.28px */
    margin-top: 14px;
  }
  & > div:nth-child(5) {
    & > button {
      border-radius: 8px;
      background: #062b6e;
      border: none;
      outline: none;
      padding: 13px 24px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
      margin-top: 20px;
      cursor: pointer;
    }
  }
  .completed {
    color: rgb(45, 151, 45);
    margin-top: 12px;
    font-weight: 600;
    font-size: 1rem;
  }
  .failed {
    color: #f00;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
  }
}

.otpVerificationContainer {
  position: relative;
  padding: 40px 24px;
  font-family: "Poppins";
  min-width: 480px;
  .close {
    position: absolute;
    top: 20px;
    right: 25px;
    cursor: pointer;
  }

  .greenCheck {
    text-align: center;
    margin-top: 40px;
    & > img {
      height: 40px;
      width: 40px;
    }
  }
  .verificationMessage {
    text-align: center;
    color: #2f2f2f;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 auto;
    margin-top: 16px;
  }
  .title,
  .note {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .note {
    text-align: center;
  }
  .message {
    color: var(--black-high, #212121);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-top: 10px;
    & > span {
      color: var(--black-high, #212121);
      font-size: 16px;
      font-weight: 500;
    }
  }
  .noteMessage {
    color: var(--black-high, #212121);
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 404px;
    text-align: center;
    margin: 0 auto;
    margin-top: 25px;
  }
  .enterOtp {
    color: #000;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-top: 30px;
    line-height: 140%; /* 19.6px */
  }
  .otpInput {
    max-width: 90%;
    & > input {
      width: 100%;
      border-radius: 4px;
      border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
      outline: none;
      padding: 13px 16px;
      color: #000;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
      margin-top: 8px;
    }
    .wrong {
      border: 1px solid red;
    }
    & > input::placeholder {
      color: rgba(146, 146, 146, 0.6);
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
    }
    & > input::-webkit-outer-spin-button,
    & > input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .wrong-error {
    display: flex;
    align-items: center;
    color: #e5261c;
    font-family: "Poppins";
    margin-top: 6px;
    gap: 6px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 18.2px */
  }
  .notRecieved {
    color: #7d7d7d;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    & > div:nth-child(2) {
      & > span {
        color: #d00909;
      }
      & > button {
        border: none;
        outline: none;
        background: inherit;
        color: #0b52d4;
        text-align: right;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
      }
    }
  }
  .submit {
    text-align: center;
    & > button {
      border-radius: 8px;
      background: #062b6e;
      border: none;
      outline: none;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 12px 40px;
      margin: 0 auto;
      margin-top: 30px;
      cursor: pointer;
    }
  }
  .beforeStartButtons {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 30px;
    & > button:nth-child(1) {
      border-radius: 8px;
      border: 1px solid #777;
      outline: none;
      background-color: inherit;
      color: #777;
      font-family: "Poppins";
      padding: 12px 24px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
    }
    & > button:nth-child(2) {
      border-radius: 8px;
      background: #062b6e;
      border: none;
      outline: none;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 12px 24px;
      cursor: pointer;
    }
  }
}

.prestartContainer {
  margin-left: 5vw;
  margin-top: 42px;
  //   height: calc(100vh - 150px);
  font-family: "Poppins";
  .pretestAcknowledge {
    //   display: flex;
    // border: 1px solid red;
    // height: 100%;
    width: 90%;
    display: flex;
    // align-items: center;
    justify-content: center;
    .detailsMain {
      margin-top: 200px;
      width: fit-content;
      //   border: 1px solid;
      text-align: center;
      .round1 {
        display: flex;
        align-items: center;
        gap: 20px;
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 28px */
        text-transform: uppercase;
        .line {
          border-bottom: 1px solid #0b52d4;
          width: 136px;
        }
      }
      .questions {
        color: #0b52d4;
        font-family: "Poppins";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 33.6px */
        margin-top: 30px;
      }
      .starting {
        color: #686565;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%; /* 28px */
        display: flex;
        gap: 16px;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
        & > div:nth-child(2) {
          background: #062b6e;
          color: #fff;
          font-family: "Poppins";
          font-size: 26px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 36.4px */
          border-radius: 50%;
          padding: 10px;
          width: 36px; /* size of the circle */
          height: 36px; /* size of the circle */
          text-align: center;
          line-height: 36px;
        }
      }
    }
  }
}

.startedRound1Container {
  max-width: 90%;
  margin: 0 auto;
  margin-top: 42px;
  font-family: "Poppins";

  .generalDetails {
    display: flex;
    justify-content: space-between;
    // border: 1px solid;
    align-items: center;
    .instructions {
      display: flex;
      align-items: center;
      color: #0b52d4;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid #0b52d4;
      padding: 5px 12px;
    }
    .questionNumber {
      & > span {
        color: #999;
        font-family: "Poppins";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        margin: 0 2px;
      }
      & > span:nth-child(1) {
        color: #0b52d4;
        font-family: "Poppins";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 33.6px */
      }
    }
    .clock {
      height: 28px;
      width: 127px;
      display: flex;
      padding: 11px 20px;
      align-items: center;
      gap: 16px;
      border-radius: 4px;
      background: #fff;
      box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.12),
        -2px -2px 4px 0px rgba(79, 79, 79, 0.09);
      color: #0b52d4;
      font-family: "Poppins";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 28px */
      .time {
        margin-bottom: 5px;
      }
    }
  }
  .questionDetailsContainer {
    background: #fff;
    padding: 24px 30px;
    border-radius: 8px;
    margin-top: 24px;
    .questionAndOption {
      -webkit-user-select: none; /* Chrome, Safari, Opera */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
      .questionNo {
        color: #212121;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
      }
      .question {
        color: #212121;
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 25.2px */
        margin-top: 24px;
      }
      .options {
        border-radius: 8px;
        background: #f8f9fb;
        width: fit-content;
        min-width: 500px;
        margin-top: 30px;
        .eachOption {
          padding: 21px 20px;
          padding: 21px 20px;
          box-sizing: border-box;
          border-bottom: 1px solid #e0e0e0;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 16px;
          & > input {
            height: 24px;
            width: 24px;
          }
          & > span {
            margin-top: 5px;
          }
        }
        & > div:nth-last-child(1) {
          border: none;
        }
      }
      .active {
        background: #ccdeff;
        font-weight: 500;
      }
    }
    .nextButton {
      text-align: right;
      & > button {
        margin-top: 16px;
        border-radius: 8px;
        background: #062b6e;
        padding: 13px 24px;
        border: none;
        outline: none;
        color: #fff;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        cursor: pointer;
      }
    }
  }
}

.tabswitch-wrapper {
  max-width: 500px;
  padding: 20px;
  position: relative;
  font-family: "Poppins";

  .close {
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
  .warning {
    text-align: center;
    margin-top: 8px;
  }
  .alert {
    color: #e5261c;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    svg {
      width: 34px;
      height: 34px;
    }
  }
  .alert2 {
    color: #2f2f2f;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin-top: 10px;
  }
  .desc,
  .desc2 {
    max-width: 310px;
    color: #2f2f2f;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 6px;
  }
  .desc2 {
    max-width: 360px;
  }
  .gotIt {
    width: 100%;
    text-align: center;
    & > button {
      border-radius: 8px;
      background: #062b6e;
      border: none;
      outline: none;
      padding: 13px 24px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
      margin-top: 20px;
      cursor: pointer;
    }
  }
}

// .instructions-wrapper {
//   position: relative;
//   padding: 20px;
//   font-family: "Poppins";
//   max-width: 495px;
//   .close {
//     position: absolute;
//     right: 20px;
//     cursor: pointer;
//   }
//   .text1 {
//     color: #7b7777;
//     font-size: 18px;
//     font-style: normal;
//     font-weight: 600;
//     line-height: normal;
//   }
//   .all-desc {
//     margin-top: 20px;
//     color: #000;
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//     & > div {
//       margin-top: 12px;
//       display: flex;
//       max-width: 430px;
//       gap: 3px;
//       .item {
//         & > span {
//           color: #7b7777;
//           font-weight: 600;
//         }
//       }
//     }
//   }
//   .okay {
//     & > button {
//       border-radius: 8px;
//   background: #062B6E;
//       border: none;
//       outline: none;
//       padding: 13px 24px;
//       color: #fff;
//       font-size: 16px;
//       font-style: normal;
//       font-weight: 500;
//       line-height: 140%; /* 22.4px */
//       margin-top: 20px;
//       cursor: pointer;
//     }
//   }
// }

.fullscreen-wrapper {
  position: relative;
  padding: 20px;
  font-family: "Poppins";
  max-width: 495px;
  .close {
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
  .warning {
    display: flex;
    align-items: center;
    color: #e5261c;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    gap: 6px;
  }
  .text1 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 16px;
  }
  .text2 {
    margin-top: 16px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .all-desc {
    margin-top: 14px;
    color: #212121;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    & > div {
      margin-top: 12px;
      display: flex;
      max-width: 430px;
      gap: 3px;
      .item {
        & > span {
          color: #7b7777;
          font-weight: 600;
        }
      }
    }
  }
  .resume {
    & > button {
      border-radius: 8px;
      background: #062b6e;
      border: none;
      outline: none;
      padding: 13px 24px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
      margin-top: 20px;
      cursor: pointer;
    }
  }
}

.finalQuestionSubmit-wrapper {
  position: relative;
  padding: 30px;
  font-family: "Poppins";
  width: 386px;
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  .title {
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    gap: 50px;
    & > button {
      cursor: pointer;
      border: none;
      outline: none;
      font-family: "Poppins";
      border-radius: 8px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: inherit;
    }
    .button1 {
      border: 1px solid #777;
      padding: 12px 18px;
      color: #777;
    }
    .button2 {
      background: #062b6e;
      color: #fff;
      padding: 12px 24px;
    }
  }
}
