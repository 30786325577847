.availibilty-edit {
  padding-top: 30px;
  .back-arrow {
    .goto {
      border: none;
      outline: none;
      cursor: pointer;
      background-color: inherit;
      display: flex;
      align-items: center;
      color: #212121;
      font-family: "Poppins";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 28px */
      text-decoration: none;
      .arrow-main {
        color: #4318ff;
        width: 24px;
        height: 24px;
      }
    }
  }
  .userName {
    color: #212121;
    font-family: "Poppins";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 30.8px */
    margin-top: 30px;
  }
  .text2 {
    font-size: 18px;
  }
  .progress-update {
    display: flex;
    gap: 80px;
    margin-top: 38px;
    .text {
      color: #000;

      /* Button Small */
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
    & > div {
      width: 248px;
    }
    .col-1 {
      width: 248px;
      .item {
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
        padding: 13px 16px;
        color: #4f4f4f;
        height: 24px;
        /* Body */
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        margin-top: 8px;
      }
    }
  }
  .submit-button {
    margin-top: 40px;
    & > button {
      border-radius: 6px;
      padding: 13px 30px;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 6px;
      background: #062b6e;
    }
  }
}
