.invitedCandidateListWrapper {
  background-color: #f5f5f5;
  padding-top: 30px;
  .title {
    margin: 0;
    padding: 0;
    margin-top: 30px;
    color: #212121;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .userTableWrapper {
    .userTableContainer {
      // padding: 16px;
      background-color: #f8f9fa;
      margin-top: 30px;
    }

    .userTable {
      width: 100%;
      border-collapse: collapse;
    }

    .tableHeadRow {
      background-color: #ccdeff75;
    }

    .tableHeader {
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 153.846% */
    }

    .tableBodyRow {
      border: none;
      &:nth-child(even) {
        background-color: #f3f3f3;
      }
      td {
        border: none;
        color: var(--Secondary-Black, #616161);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
      }

      // &:hover {
      //   background-color: #ececec;
      // }
    }

    .moreIcon {
      color: #555;
    }

    .menu {
      .MuiMenuItem-root {
        font-size: 14px;
      }
    }
  }

  /* Pagination Styling */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: #062b6e;
    button {
      font-weight: 600;
    }
    /* Customizing pagination color */
    .MuiPaginationItem-root {
      color: inherit;
    }
  }
}
