.rolesPage {
  //   padding: 20px;
  //   background-color: #f9f9f9;
  //   border: 1px solid;
  padding-top: 30px;

  .title {
    margin: 0;
    padding: 0;
    color: #212121;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 30.8px */
  }

  /* Action row that contains the search bar and "Create New Role" button */
  .actionRow {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    /* Style for the search input */
    .searchField {
      width: 400px;
      input {
        padding: 10px 16px;
        padding-left: 0px;
      }
    }

    /* Style for the "Create New Role" button */
    .createRoleButton {
      border-radius: 4px;
      background: #062b6e;
      color: #fff;
      padding: 7px 10px;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      &:hover {
        background: #0941a9;
      }
    }
  }
  .tableContainer {
    /* Table Styling */
    .tableHead {
      tr {
        background-color: #ccdeff75;
        color: var(--Secondary-Black, #616161);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }
    .tableBody {
      tr {
        border-bottom: none;
        td {
          color: var(--Secondary-Black, #616161);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          padding: 12px 16px;
          border-bottom: none;
        }
        .dropdownContainer {
          color: #616161;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 0px;
          &:hover {
            background-color: inherit;
          }
        }

        .dropdownMenu {
          position: absolute;
          top: 150%;
          left: 0;
          background-color: white;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
          z-index: 10;
          border-radius: 4px;
          background: #fff;
          box-shadow: -2px -2px 2px 0px rgba(79, 79, 79, 0.12),
            2px 2px 2px 0px rgba(79, 79, 79, 0.12);
          color: #212121;
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          .invitedButton {
            color: #062b6e;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 2px;
            color: #616161;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .editIcon {
          color: #062b6e;
        }
        .closeButton {
          border-radius: 4px;
          border: 1px solid #0b52d4;
          color: #0b52d4;
          font-family: "Poppins";
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 6px 5px;
        }
      }
      .MuiTable-root {
        min-width: 650px;
      }

      .MuiTableCell-root {
        padding: 16px;
        font-size: 14px;
        font-weight: 500;
      }

      /* Alternate row colors */
      .evenRow {
        background-color: #ffffff; /* White for even rows */
      }

      .oddRow {
        background-color: #f5f5f5; /* Light grey for odd rows */
      }

      /* Table header row */
      .MuiTableHead-root .MuiTableCell-root {
        background-color: #1976d2; /* Header background color */
        color: #fff; /* Header text color */
        font-weight: 600;
        text-align: left;
      }
    }
  }

  /* Pagination Styling */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: #062b6e;
    button {
      font-weight: 600;
    }
    /* Customizing pagination color */
    .MuiPaginationItem-root {
      color: inherit;
    }
  }
}
.popupContainer {
  // Popup Header
  min-width: 1000px;
  &__header {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 20px;
    padding-top: 40px;
    position: relative;

    // Close Icon (X)
    .closeIcon {
      position: absolute;
      right: 15px;
      top: 5px;
      color: #333;
      &:hover {
        color: #d32f2f;
      }
    }
  }

  // Popup Actions (Buttons)
  &__actions {
    display: flex;
    justify-content: center;
    padding: 20px;
    gap: 12px;

    // Cancel Button
    .cancelButton {
      color: #000;
      border: 1px solid #c4c4c4;
      border-radius: 6px;
      font-size: 14px;
      padding: 8px 20px;
      text-transform: none;
    }

    // Confirm (Close Role) Button
    .confirmButton {
      background-color: #0b52d4 !important;
      color: #fff;
      border-radius: 6px;
      font-size: 14px;
      padding: 8px 20px;
      text-transform: none;
    }
  }
}
