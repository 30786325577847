.candidate-info-main {
  padding: 60px 40px;

  .candidate-info-body {
    display: flex;
    margin-top: 100px;
    gap: 40px;
    font-family: Poppins;
    font-style: normal;
    line-height: normal;

    .candidate-info-left {
      width: 25%;
      height: 544px;
      flex-shrink: 0;
      border-radius: 12px;
      background: #fff;
      box-shadow: -2px -2px 4px 0px rgba(33, 33, 33, 0.12),
        2px 2px 4px 0px rgba(33, 33, 33, 0.12);
      padding-left: 20px;
      letter-spacing: 0.2px;
      position: relative;

      .candidate-image {
        width: 150px;
        height: 150px;
        border-radius: 150px;
        position: absolute;
        top: -75px;
        left: 30%;
      }

      .role {
        color: #062b6e;
        font-size: 13px;
        font-weight: 600;
        margin-top: 4px;
      }
      .name {
        color: #212121;
        font-size: 16px;
        font-weight: 600;
        margin-top: 102px;
      }

      .location,
      .experience,
      .domain {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 500;
        color: #212121;
      }

      .availability,
      .job-type {
        margin-top: 16px;
        max-width: 195px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid #062b6e;
        background: #dfe7f6;
        color: #062b6e;
        font-size: 13px;
        display: flex;
        align-items: center;
        padding: 2px 8px;

        & > span:nth-child(1) {
          font-weight: 400;
        }

        & > span:nth-child(2) {
          font-weight: 600;
        }
      }

      .technologies {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        margin-top: 24px;

        & > span {
          border-radius: 4px;
          background: rgba(201, 215, 240, 0.6);
          color: #062b6e;
          font-size: 14px;
          font-weight: 500;
          padding: 7px 8px 6px 8px;
        }
      }

      .hire-button {
        margin-top: 30px;
        display: flex;
        width: 129px;
        height: 40px;
        padding: 10px 24px 9px 24px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 4px;
        background: #34a853;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        border: none;
        margin-left: 25%;
      }
    }

    .candidate-info-right {
      width: 70%;
      .accordion-main {
        border-radius: 12px;
        background: #fff;
        box-shadow: -2px -2px 4px 0px rgba(33, 33, 33, 0.12),
          2px 2px 4px 0px rgba(33, 33, 33, 0.12);
        padding: 30px;
        letter-spacing: 0.2px;
        margin-bottom: 24px;

        .header {
          display: flex;
          color: #062b6e;
          font-size: 18px;
          font-weight: 600;
          justify-content: space-between;
          cursor: pointer;
        }

        .body {
          margin-top: 24px;

          .vetted-technical-skills-main {
            .vetted-technical-skills-items {
              display: flex;
              flex-direction: column;
              gap: 24px;
              margin-bottom: 30px;
              .skill-header {
                display: flex;
                gap: 245px;
                & > div {
                  color: #333;
                  font-size: 16px;
                  font-weight: 600;
                  letter-spacing: 0.2px;
                }
              }
              .vetted-skills-item {
                display: flex;
                gap: 150px;

                .skill,
                .experience {
                  color: #4f4f4f;
                  font-size: 16px;
                  font-weight: 500;
                  letter-spacing: 0.2px;
                }
              }
            }
            .skill-description-main {
              border-top: 1px solid #e0e0e0;

              .skill-title {
                margin-top: 24px;
                color: #4f4f4f;
                font-size: 15px;
                font-weight: 600;
                letter-spacing: 0.2px;
              }

              .skill-description {
                color: #333;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.2px;
                margin-top: 14px;
              }
            }
          }

          .work-experience-items {
            padding-left: 15px;
            display: flex;
            flex-direction: column;
            gap: 32px;
            .work-experience-item {
              display: flex;
              gap: 20px;
              .item-container {
                letter-spacing: 0.2px;
                border-left: 1px solid #e0e0e0;
                padding-left: 30px;
                .designation-main {
                  .designation {
                    color: #212121;
                    font-size: 18px;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    position: relative;

                    .circle-with-green-dot {
                      position: absolute;
                      left: -45px;
                      top: 0px;
                    }
                  }
                  .company {
                    color: #4f4f4f;
                    font-size: 16px;
                    font-weight: 600;
                    margin-top: 8px;
                  }
                  .location {
                    color: #4f4f4f;
                    font-size: 14px;
                    font-weight: 500;
                    margin-top: 6px;
                  }
                }

                .project-main {
                  margin-top: 16px;
                  .project-header {
                    color: #909090;
                    font-size: 14px;
                    font-weight: 500;
                  }
                  .project-title {
                    color: #062b6e;
                    font-size: 16px;
                    font-weight: 500;
                    margin-top: 6px;
                  }
                  .project-description {
                    color: #4f4f4f;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 23px; /* 164.286% */
                    margin-top: 8px;
                  }
                }

                .responsibilities-main {
                  margin-top: 16px;
                  .responsibilities-header {
                    color: #909090;
                    font-size: 14px;
                    font-weight: 500;
                  }
                  .responsibilities-list {
                    padding-left: 0px;
                    list-style: none;

                    li {
                      position: relative;
                      margin-bottom: 10px;
                      padding-left: 20px;
                      color: #333;
                      font-size: 13px;
                      font-weight: 400;
                      line-height: 23px; /* 176.923% */

                      &::before {
                        content: "•";
                        position: absolute;
                        left: 0;
                        color: #9a9a9a;
                        font-size: 24px;
                      }
                    }
                  }
                }

                .tech-stacks {
                  margin-top: 16px;
                  .tech-stack-header {
                    color: #909090;
                    font-size: 14px;
                    font-weight: 500;
                  }
                  .tech-stack-list {
                    margin-top: 10px;
                    display: flex;
                    gap: 16px;
                    flex-wrap: wrap;
                    & > span {
                      color: #062b6e;
                      font-size: 14px;
                      font-weight: 500;
                      padding: 7px 10px 6px 10px;
                      border-radius: 4px;
                      background: rgba(201, 215, 240, 0.6);
                    }
                  }
                }
              }
            }
          }

          .skills {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            .skill-item {
              border-radius: 4px;
              border: 1px solid #062b6e;
              background: #dfe7f6;
              padding: 12px;
              letter-spacing: 0.2px;
              color: #062b6e;
              font-size: 16px;

              .skill {
                font-weight: 600;
              }
              .experience {
                font-weight: 400;
                margin-left: 18px;
              }
            }
          }

          .soft-skills-main {
            .soft-skills-items {
              display: flex;
              flex-direction: column;
              gap: 30px;
              margin-bottom: 30px;

              .soft-skill-item {
                align-items: center;
                display: flex;

                .skill {
                  width: 220px;
                  height: 48px;
                  border-radius: 4px;
                  background: #dfe7f6;
                  padding: 12px;
                  box-sizing: border-box;
                  display: flex;
                  gap: 10px;

                  & > span {
                    color: #4f4f4f;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0.2px;
                  }
                }

                .remarks {
                  color: #4f4f4f;
                  font-size: 16px;
                  font-weight: 500;
                  letter-spacing: 0.2px;
                  margin-left: 70px;
                }
              }
            }

            .description {
              border-top: 1px solid #e0e0e0;
              padding-top: 14px;
            }
          }

          .education-certifications {
            display: flex;
            flex-direction: column;
            gap: 30px;
            .education-certification-item {
              .name {
                color: #212121;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0.2px;
                display: flex;
                align-items: center;
                gap: 12px;
              }

              .place {
                color: #4f4f4f;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0.2px;
                margin-top: 8px;
              }

              .location {
                color: #4f4f4f;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.2px;
                margin-top: 6px;
              }
            }
          }

          .recommendations {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .recommendation-item {
              .header {
                color: #212121;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0.2px;
              }

              .name {
                color: #4f4f4f;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0.2px;
                margin-top: 8px;
              }

              .description {
                color: #4f4f4f;
                font-size: 14px;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: 0.2px;
                margin-top: 10px;
              }
            }
          }

          .languages {
            display: flex;
            flex-wrap: wrap;
            gap: 60px;

            .language-item {
              display: flex;
              align-items: center;

              & > span:nth-child(1) {
                width: 116px;
                height: 44px;
                box-sizing: border-box;
                border-radius: 4px;
                background: #dfe7f6;
                color: #4f4f4f;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0.2px;
                padding: 10px 14px;
                margin-right: 9px;
              }

              & > span:last-child {
                color: #333;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.2px;
                margin-left: 20px;
              }
            }
          }

          .interests {
            display: flex;
            gap: 20px;

            & > span {
              padding: 8px 12px;
              background-color: #dfe7f6;
              color: #4f4f4f;
              font-size: 16px;
              font-weight: 600;
              letter-spacing: 0.2px;
            }
          }
        }
      }

      .share-main {
        border-radius: 8px;
        background: #dfe7f6;
        padding: 10px 0px 10px 20px;
        display: flex;
        align-items: center;

        .share {
          color: #5f6368;
        }

        .share-title {
          color: #4f4f4f;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0.2px;
          margin-left: 16px;
          margin-right: 20px;
        }

        .social-media {
          border-left: 1px solid rgba(95, 99, 104, 0.4);
          padding-left: 40px;
          display: flex;
          align-items: center;
          .linkedin {
            margin-right: 30px;
          }
          .instagram,
          .linkedin {
            width: 30px;
            height: 30px;
            color: #062b6e;
          }
        }
      }
    }
  }
}
